<template>
  <div :class="`listerine ${getCurrentLangs.lang}`" data-view>
    <Header/>
    <scrollAnalytics></scrollAnalytics>

    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('listerine.head')"></div>

        <router-link to="/cases/" class="top__back-btn cursor-hover">
          <div class="top__arrow">

            <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071" stroke="black" stroke-linecap="round"/>
              <path d="M15.2988 29.973L38.2398 29.9732" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>

          {{ $t('back-btn') }}
        </router-link>
      </div>

      <h1 class="top__title animation iosTitle type1">
        <span class="text-wrapper" v-for="(key, value) in $t('listerine.title')" :key="value">
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class='banner'>
      <picture>
        <source srcset="@/assets/img/cases/listerine/banner-mob.jpg" media="(max-width: 425px)">
        <source srcset="@/assets/img/cases/listerine/banner-tablet.jpg" media="(max-width: 768px)">
        <img v-parallax.modifier.absY="0.1" src="@/assets/img/cases/listerine/banner.jpg" alt="">
      </picture>
    </section>

    <section class="challenge">
      <div class="challenge__double-block">
        <div class="challenge__left">
          <p class="challenge__left-text">{{ $t('listerine.challenge.left-text') }}</p>
        </div>
        <div class="challenge__right">
          <p class="challenge__right-title">{{ $t('listerine.challenge.right-title') }}</p>
          <p class="challenge__right-text">{{ $t('listerine.challenge.right-text') }}</p>
        </div>
      </div>
    </section>

    <div class="parallax parallax-race" v-parallax.modifier.absY="0.1">
      <div class="race-bg-wrapper">

        <picture>
          <source media="(max-width:425px)" srcset="../../assets/img/cases/listerine/race-bg-mob.png">
          <source media="(max-width:768px)" srcset="../../assets/img/cases/listerine/race-bg-tablet.png">
          <img src="../../assets/img/cases/listerine/race-bg.png">
        </picture>

        <div class="race-bg">
          <section class="race">
            <div class="race__double-block">
              <div class="race__left">
                <p class="race__left-text" v-html="$t('listerine.race.left-text')"></p>
              </div>
              <div class="race__right">
                <p class="race__right-bold" v-html="$t('listerine.race.right-bold')"></p>
              </div>
            </div>
          </section>
        </div>

      </div>
    </div>

    <section class="execution">
      <div class="execution__double-block">

        <div class="execution__left">
          <p class="execution__left-text" v-html="$t('listerine.solution.left-text')"></p>
          <!-- <img class="execution__left-img" src="../../assets/img/cases/listerine/pos-left.png" alt=""> -->

          <div class="execution__left-img-wrapper">

            <svg class="execution__arrow" width="174" height="43" viewBox="0 0 174 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 17.2369C42 -0.498339 113 -15.5047 172 40.8835M172 40.8835L160.5 17.2367M172 40.8835L144 36.336" stroke="#354F57" stroke-width="2" stroke-linecap="round" stroke-dasharray="8 8"/>
            </svg>

            <img class="execution__left-img" src="../../assets/img/cases/listerine/pos-left.jpg" alt="">

          </div>
        </div>

        <div class="execution__right">

          <p class="execution__right-bold" v-html="$t('listerine.solution.right-title')"></p>

          <p class="execution__right-text" v-html="$t('listerine.solution.right-text[0]')"></p>
          <p class="execution__right-text" v-html="$t('listerine.solution.right-text[1]')"></p>
          <p class="execution__right-text last" v-html="$t('listerine.solution.right-text[2]')"></p>

          <div class="execution__right-img-wrapper">
            <img v-parallax.modifier.absY="0.05" src="../../assets/img/cases/listerine/pos.png" alt="">
          </div>

        </div>

      </div>

      <div class="execution__double-block bottom">
        <img class="execution__left-img" src="@/assets/img/cases/listerine/pos-left-tablet.png" alt="">
        <svg class="decor" width="141" height="110" viewBox="0 0 141 110" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.9885 108.293C46.9606 103.824 116.215 82.0083 135.952 2.16895M135.952 2.16895L139.055 29.0732M135.952 2.16895L114.387 20.1514" stroke="#354F57" stroke-width="2" stroke-linecap="round" stroke-dasharray="8 8"/>
        </svg>
        <div class="execution__img-wrapper">
          <img src="../../assets/img/cases/listerine/pos.png" alt="">
        </div>
      </div>
    </section>

    <section class="video">
      <div class="video__video-wrapper cursor-hover">
        <div class="video__play-btn cursor-hover" @click="playVideo" v-bind:class="[{ active: !videoTrigger}]"></div>
        <video v-if="getCurrentLangs.lang === 'en'" @click="playVideo" ref="video" poster="../../assets/img/cases/listerine/listerine-poster.png">
          <source src="@/assets/video/listerine.mp4" type="video/mp4">
        </video>
        <video v-else @click="playVideo" ref="video" poster="../../assets/img/cases/listerine/listerine-poster.png">
          <source src="@/assets/video/listerineUa.mp4" type="video/mp4">
        </video>
      </div>
    </section>

    <div class="bg-game">
      <section class="game" v-parallax.modifier.absY="0.1">
        <div class="game__double-block">
          <p class="game__left-text" v-html="$t('listerine.game.left-text')"></p>
          <p class="game__right-text" v-html="$t('listerine.game.right-text')"></p>
        </div>
        <div class="game__double-block gallery">
          <div class="game__left">
            <img src="../../assets/img/cases/listerine/game-0.jpg" alt="">
          </div>
          <div class="game__right desc">
            <img class="game__right-img" src="../../assets/img/cases/listerine/game-25.jpg" alt="">
            <img class="game__right-img" src="../../assets/img/cases/listerine/game-75.jpg" alt="">
            <img class="game__right-img" src="../../assets/img/cases/listerine/game-99.jpg" alt="">
            <img class="game__right-img" src="../../assets/img/cases/listerine/tutorial-1.jpg" alt="">
            <img class="game__right-img" src="../../assets/img/cases/listerine/tutorial-2.jpg" alt="">
            <img class="game__right-img clean" src="../../assets/img/cases/listerine/garant-clean.jpg" alt="">
            <img class="game__right-img" src="../../assets/img/cases/listerine/get-gift.jpg" alt="">
            <img class="game__right-img" src="../../assets/img/cases/listerine/win.jpg" alt="">
          </div>
          <div class="game__right mob">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/game-0.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/game-25.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/game-75.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/game-99.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/tutorial-1.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/tutorial-2.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/get-gift.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/win.jpg" alt="">
            <img class="game__right-img-mob" src="../../assets/img/cases/listerine/garant-clean.jpg" alt="">
          </div>
        </div>
      </section>
    </div>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/NewFooter.vue';
import similar from '@/components/similar.vue';
import anime from 'animejs';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Listerine',
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      videoTrigger: false,
      similarData: {
        title: 'similar-title-type1',
        prevLink: "/cases/allegra",
        nextLink: "/cases/hepato",
        linkShow: true,
        slider: [
          'slider-hepatoconstructor',
          'slider-meet-vitrolya',
          'slider-olfen',
          'slider-shift-work-disorder',
        ],
      },

    };
  },

  mounted() {


    setTimeout(() => {


      this.setCurrentPage('cases');
      const whySection = document.querySelector('.top');
      const textWrapperAll = whySection.querySelectorAll('.text-wrapper .letters');
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes('®')) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>").replace("®", "<sup>®</sup>");

        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime.timeline({loop: false})
          .add({
            targets: '.text-wrapper .letter',
            translateY: ['1.1em', 0],
            translateZ: 0,
            duration: 750,
            delay: (el, i) => 50 * i,
          });
      });
    }, 20)
  },
  methods: {
    ...mapMutations([
      'setCurrentPage',
    ]),

    playVideo() {
      const mediaVideo = this.$refs.video;
      if (mediaVideo.paused) {
        mediaVideo.play();
        this.videoTrigger = true;
        gtag('event', 'scroll', {'event_category': 'play video', 'event_label': this.$route.path})

      } else {
        mediaVideo.pause();
        this.videoTrigger = false;
      }
    },
  },

  computed: {
    ...mapGetters(['getCurrentLangs'])
  }
}
</script>

<style lang="scss" scoped>
.listerine {
  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  .top {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 52px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 40px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 10px 20px 24px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }

    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all .2s ease;

      @media screen and (min-width: 1023px) {

        &:hover {

          svg {
            path {
              stroke: #29F6D9;

            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29F6D9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin: 0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all .2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;


      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 80px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;

      position: relative;

      .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: 1em;
      }

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }

      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .banner {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .challenge {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 120px 45px 100px 45px;


    @media screen and (max-width: 768px) {
      padding: 48px 45px 60px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 36px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {

        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;
      max-width: calc(100% - 66%);
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
        max-width: 100%;
      }
    }

    &__left-text {
      font-size: 16px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;
      line-height: 2.4em;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 15px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }

    }
  }

  .parallax-race {
    margin-top: 85px;

    @media screen and (max-width: 768px) {
      margin-top: 64px;
    }

    @media screen and (max-width: 425px) {
      margin-top: 48px;
    }
  }

  .race-bg-wrapper {

    img {
      width: 100%;
      height: auto;
      display: block;
      margin-bottom: -1px;
    }

    .race-bg {
      width: 100%;
      background: #E9ECEF;


      .race {

        max-width: calc(1108px + 90px);
        width: 100%;

        margin: 0 auto;

        padding: 80px 45px 100px 45px;

        @media screen and (max-width: 768px) {
          padding: 56px 45px;
        }

        @media screen and (max-width: 425px) {
          padding: 48px 20px;
        }

        &__double-block {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 600px) {
            flex-direction: column;
          }
        }

        &__left {
          max-width: calc(100% - 66%);
          width: 100%;
          min-width: 1px;
          margin-right: 15px;
          @media screen and (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 24px;
          }
        }

        &__left-text {
          font-size: 16px;
          line-height: 2.7em;

          letter-spacing: 0.08em;
          text-transform: uppercase;

          color: black;

          font-weight: 500;
          text-align: left;

          @media screen and (max-width: 500px) {
            font-size: 12px;
          }
        }

        &__right {
          margin-left: 15px;
          max-width: 66%;

          @media screen and (max-width: 600px) {
            max-width: 100%;
            margin-left: 0;
          }
        }

        &__right-bold {
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          text-align: left;

          color: black;

          @media screen and (max-width: 500px) {
            font-size: 20px;
            line-height: 28px;
          }
        }

      }


      img {
        margin-top: 45px;
        width: 100%;
        height: auto;
        display: block;

        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }

        @media screen and (max-width: 500px) {
          margin-top: 28px;
        }
      }
    }
  }


  .execution {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 90px 45px 0 45px;

    &__right-img-wrapper {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 56px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 0 20px;
    }


    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      &.bottom {
        overflow: hidden;
        width: 100%;
        position: relative;
        align-items: flex-end;


        display: none;

        @media screen and (max-width: 768px) {
          flex-direction: column-reverse;
          align-items: center;
          display: flex;
        }

        .execution__left-img {
          position: relative;
          z-index: 2;
          // max-width: 34%;
          transform: translateX(20%);
          object-fit: contain;

          @media screen and (max-width: 768px) {
            transform: translateX(0%);
            max-width: 378px;
            margin-top: 20px;
          }
          @media screen and (max-width: 600px) {
            width: 62%;
            max-width: unset;
          }
        }

        .decor {
          display: none;
          position: absolute;
          z-index: 2;
          bottom: 36%;
          right: 14%;
          @media screen and (max-width: 768px) {
            display: block;
          }
          @media screen and (max-width: 600px) {
            max-width: 26%;
            bottom: 57%;
            right: 5%;
          }
        }
      }
    }

    &__arrow {
      position: absolute;
      top: -40px;
      right: -130px;
      z-index: 2;
    }

    &__left-img-wrapper {
      max-width: 332px;
      min-width: 1px;
      position: relative;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__left {
      margin-right: 15px;
      max-width: calc(100% - 66%);
      width: 100%;
      min-width: 1px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      text-align: left;
      color: var(--text-primary-color-case);

      font-weight: 500;
      font-size: 16px;
      line-height: 2.4em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__right {
      max-width: 66%;
      width: 100%;
      margin-left: 15px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      color: var(--text-primary-color-case);
      margin-bottom: 8px;

      &.last {
        margin-bottom: 48px;
      }

      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__right-bold {
      text-align: left;

      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      color: var(--text-primary-color-case);
      margin-bottom: 8px;

      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 28px;
      }

      &.second {
        margin-top: 48px;
        margin-bottom: 60px;

        @media screen and (max-width: 600px) {
          margin-bottom: 29px;
        }
      }
    }

    &__img {
      max-width: 50%;
      width: 100%;
      height: auto;
      display: block;

      @media screen and (max-width: 425px) {
        max-width: calc(50% - 10px);
      }

      &.first {
        margin-right: 12px;
        margin-bottom: 22px;
        @media screen and (max-width: 600px) {
          margin-right: 10px;
        }
      }

      &.second {
        margin-top: 22px;
        margin-left: 12px;
        @media screen and (max-width: 600px) {
          margin-left: 10px;
        }
      }
    }

  }

  .video {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 86px 45px 98px 45px;

    @media screen and (max-width: 768px) {
      padding: 60px 45px;
    }

    @media screen and (max-width: 600px) {
      padding: 40px 20px 48px 20px;
    }

    &__video-wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: 'Play';
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29F6D9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all .2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }

  .bg-game {
    width: 100%;
    background-color: #F07DEB;
    overflow: hidden;
    padding-bottom: 60px;
    @media screen and (max-width: 960px) {
      padding-bottom: 0;
    }

    .game {
      max-width: calc(1108px + 90px);
      width: 100%;
      margin: 0 auto;
      padding: 120px 45px 60px 45px;
      max-height: 1200px;

      @media screen and (max-width: 960px) {
        max-height: 1050px;
      }
      @media screen and (max-width: 768px) {
        max-height: 800px;
        padding: 94px 45px 56px 45px;
      }
      @media screen and (max-width: 600px) {
        max-height: unset;
        padding: 64px 20px 100px 20px;
      }

      &__double-block {
        display: grid;
        grid-template-columns: 1fr 2.15fr;
        grid-column-gap: 30px;
        margin-bottom: 40px;
        @media screen and (max-width: 768px) {
          grid-column-gap: 24px;
        }
        @media screen and (max-width: 600px) {
          grid-template-columns: 1fr;
        }

        &.gallery {
          @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
          }
        }
      }

      &__left {
        text-align: left;

        img {
          max-width: 100%;
        }

        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      &__right {
        display: grid;
        grid-template-columns: repeat(3, 22%);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        @media screen and (max-width: 768px) {
          grid-column-gap: 24px;
          grid-row-gap: 24px;
        }
        @media screen and (max-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }

        &.desc {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }

        &.mob {
          display: none;
          @media screen and (max-width: 600px) {
            display: grid;
          }
        }
      }

      &__right-img {
        object-fit: contain;

        &:nth-of-type(7) {
          grid-row: 3;
        }

        &.clean {
          grid-column: 3;
          grid-row: 2 / 43;
          max-width: 213%;

          @media screen and (max-width: 960px) {
            max-width: 215%;
          }
        }
      }

      &__left-text {
        font-size: 16px;
        line-height: 1.7em;

        letter-spacing: 0.08em;
        text-transform: uppercase;

        color: black;
        font-weight: 500;
        text-align: left;
        margin-top: 9px;

        @media screen and (max-width: 600px) {
          margin-bottom: 24px;
          margin-top: 0;
        }

        @media screen and (max-width: 425px) {
          font-size: 12px;
        }
      }

      &__right-text {
        text-align: left;
        font-size: 24px;
        line-height: 1.7em;

        color: black;
        font-weight: 500;
        margin-bottom: 8px;

        @media screen and (max-width: 768px) {
          margin-bottom: 0px;
        }
        @media screen and (max-width: 600px) {
          line-height: 28px;
        }
        @media screen and (max-width: 425px) {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
